<template>
  <div>
    <eden-table-actions :title="title" @search="searchOrders">
      <template slot="title">
        <eden-periods class="ml-10" :loading="loading" :default-period="'today'" @set-period="getOrders"
          addCustomFilter />
      </template>
      <template slot="actions">
        <template v-if="selectedOrders.length">
          <beauty-schedule-actions v-show="showSelectedOrders" ref="beautyActions" :selected-orders="selectedOrders"
            @error="selectedOrders = []" @updated="getOrders({ period: period })" />
        </template>
        <template v-else>
          <eden-service-partners class="ml-10" :loading="loading" :allow-all="true"
            :service-partner-id.sync="servicePartnerId" :service-partner-name.sync="servicePartnerName"
            :service="'beauty'" />
          <el-button type="plain" icon="eden-icon-upload" :disabled="loading || !pageData.length"
            @click="showExport = true">
            Export
          </el-button>
        </template>
      </template>
    </eden-table-actions>

    <template>
      <eden-loader v-if="loading" />
      <template v-else>
        <template v-if="pageData.length">
          <el-table :data="pageData" @select-all="setSelectedOrders" @selection-change="setSelectedOrders">
            <el-table-column v-if="allowMarking" type="selection" width="45"></el-table-column>
            <el-table-column width="100">
              <template slot="header">
                <span>Order Id</span>
              </template>
              <template slot-scope="scope">
                <router-link :to="{
                  name: 'orders.order',
                  params: { id: scope.row.order_id },
                }">
                  <span class="font-sm text-primary">
                    {{ scope.row.order_id }}</span></router-link>
              </template>
            </el-table-column>
            <el-table-column width="220">
              <template slot="header">
                <span class="font-sm">Customer</span>
              </template>
              <template slot-scope="scope">
                <router-link :to="{
                  name: 'customers.individual',
                  params: { id: scope.row.customer_id },
                }">
                  <span class="font-sm">
                    {{ formatName(scope.row.customer) }}</span></router-link>
                <span class="font-xsm text-grey-tertiary">
                  {{ scope.row.gardener }}</span>
              </template>
            </el-table-column>
            <el-table-column width="120">
              <template slot="header">
                <div class="table--header">
                  <span>Order Type </span>
                </div>
              </template>
              <template slot-scope="scope">
                <span class="font-sm">
                  {{ formatText(scope.row.order_type) }}</span>
              </template>
            </el-table-column>
            <el-table-column width="150">
              <template slot="header">
                <div class="table--header">
                  <span>Channel</span>
                </div>
              </template>
              <template slot-scope="scope">
                <span class="font-sm">
                  {{ formatChannel(scope.row.channel_id) }}
                </span>
              </template>
            </el-table-column>
            <el-table-column width="150">
              <template slot="header">
                <span class="font-sm">Type </span>
              </template>
              <template slot-scope="scope">
                <span class="font-sm"> {{ formatText(scope.row.type) }}</span>
              </template>
            </el-table-column>
            <el-table-column width="150">
              <template slot="header">
                <span class="font-sm">Service </span>
              </template>
              <template slot-scope="scope">
                <span class="font-sm" v-for="(item, index) in scope.row.services_list" :key="index">
                  <p>{{ formatText(item.beauty_category?.name) }}</p>
                </span>
              </template>
            </el-table-column>
            <el-table-column width="180">
              <template slot="header">
                <span class="font-sm"> Service Date </span>
              </template>
              <template slot-scope="scope">
                <span class="font-sm">
                  {{ formatDate(scope.row.arrival_date, "m do, y") }}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column width="200">
              <template slot="header">
                <span class="font-sm"> Service Partner(s)</span>
              </template>
              <template slot-scope="scope">
                <p class="font-sm">{{ scope.row.sp_first || "-" }}</p>
                <p class="font-sm">{{ scope.row.sp_second || "-" }}</p>
              </template>
            </el-table-column> -->
            <el-table-column width="150">
              <template slot="header">
                <div class="table--header">
                  <span>Phone Number </span>
                </div>
              </template>
              <template slot-scope="scope">
                <span class="font-sm">
                  {{ formatPhoneNumber(scope.row.customer_phone_number) }}</span>
              </template>
            </el-table-column>
            <el-table-column width="200">
              <template slot="header">
                <span class="font-sm">Location Area </span>
              </template>
              <template slot-scope="scope">
                <span class="font-sm">{{ scope.row.locationarea }} ({{ scope.row.priority }})</span>
              </template>
            </el-table-column>
            <el-table-column width="250">
              <template slot="header">
                <div class="table--header">
                  <span>Address </span>
                </div>
              </template>
              <template slot-scope="scope">
                <span class="font-sm"> {{ scope.row.customer_address }}</span>
              </template>
            </el-table-column>
            <el-table-column width="160">
              <template slot="header">
                <div class="table--header">
                  <span>Ordered At</span>
                </div>
              </template>
              <template slot-scope="scope">
                <el-tag type="success" class="text-normalize">
                  {{ formatDateAndTime(scope.row.created_at, "do m,y") }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column width="200">
              <template slot="header">
                <div class="table--header">
                  <span>Status </span>
                </div>
              </template>
              <template slot-scope="scope">
                <el-tag :type="setType(
                  scope.row.status.includes('Assigned')
                    ? 'normal'
                    : scope.row.status == 'Completed'
                      ? 'completed'
                      : 'pending',
                )
                  ">{{ scope.row.status }}</el-tag>
              </template>
            </el-table-column>
          </el-table>
          <eden-pagination v-if="showPagination" :from="from" :to="to" :total="total" :current-page.sync="page" />
        </template>
        <eden-content-empty v-else :text="'No data'" />
      </template>
    </template>
    <orders-schedule-beauty-export :show.sync="showExport" :period="period" :custom-date="custom" :data="exportData" :data-time="pageDataTime"
      :service-partner-id="servicePartnerId" />
  </div>
</template>
<script>
import BeautyScheduleActions from "@/components/Orders/Schedule/Beauty/BeautyScheduleActions";
import OrdersScheduleBeautyExport from "@/components/Orders/Schedule/Beauty/OrdersScheduleBeautyExport";

import { formatBeautyData } from "@/components/Orders/Schedule/format-schedule-data";

import schedule from "@/requests/orders/schedule";
import * as actions from "@/store/action-types";

export default {
  name: "OrdersScheduleBeauty",
  components: {
    BeautyScheduleActions,
    OrdersScheduleBeautyExport,
  },
  data() {
    return {
      loading: false,
      page: 1,
      pageData: [],
      showPagination: true,
      period: "today",
      servicePartnerId: "all",
      servicePartnerName: "",
      showExport: false,
      exportData: [],
      selectedOrders: [],
      showSelectedOrders: false,
      pageDataTime: "",
      custom: {
        from: null,
        to: null,
      },
    };
  },
  computed: {
    orders() {
      return this.$store.getters.orders_schedule.beauty;
    },
    from() {
      return this.orders.pages[this.page].from;
    },
    to() {
      return this.orders.pages[this.page].to;
    },
    total() {
      return this.orders.total;
    },
    title() {
      const total = this.total;
      const result = this.pageData.length;
      const length = this.showPagination ? total || 0 : result;

      return `${length} Order${length > 1 ? "s" : ""}`;
    },
    allowMarking() {
      return false;
    },
  },
  watch: {
    page() {
      const pageFetched = !!this.orders.pages[this.page];
      if (!pageFetched) {
        this.getOrders({
          period: this.period, from: this.custom.from,
          to: this.custom.to,
        });
      }
    },
    servicePartnerId() {
      this.getOrders({
        period: this.period,
        from: this.custom.from,
        to: this.custom.to,
      });
    },
  },
  created() {
    this.getOrders({
      period: this.period
    });
  },
  methods: {
    getOrders({ period, from, to }) {
      this.selectedOrders = [];
      const { start_date, end_date } = this.formatStartAndEndDate({
        period,
        from,
        to,
      });
      this.custom.from = start_date;
      this.custom.to = end_date;
      this.loading = true;

      this.$store
        .dispatch(actions.GET_ORDERS_SCHEDULE, {
          service: "beauty",
          servicePartnerId: this.servicePartnerId,
          start_date: this.custom.from,
          end_date: this.custom.to,
          page: this.page,
        })
        .then(() => {
          this.period = period;
          this.setPageData();
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    formatChannel(channel_id) {
      let name;
      switch (channel_id) {
        case 1:
          name = "Mobile app";
          break;

        case 2:
          name = "Green house";
          break;

        case 3:
          name = "Web app";
          break;

        default:
          name = "--";
      }
      return name;
    },
    searchOrders(query) {
      if (!query) {
        this.loading = false;
        this.setPageData();
        return;
      }
      this.loading = true;
      const start_date = this.custom.from;
      const end_date = this.custom.to;
      schedule
        .search({
          servicePartnerId: this.servicePartnerId,
          service: "beauty",
          query: this.stringTrim(query),
          start_date,
          end_date,
        })
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.pageData = data ? formatBeautyData(data) : [];
            this.exportData = this.pageData;
            this.showPagination = false;
          } else {
            this.$message.info("No customer order");
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    setPageData() {
      this.pageData = this.orders.pages[this.page].data;
      this.pageData = formatBeautyData(this.pageData);
      this.showPagination = true;
      this.pageDataTime = new Date();
    },
    setPeriod(period) {
      this.period = period;
    },
    setSelectedOrders(orders) {
      this.selectedOrders = orders;
      this.showSelectedOrders = !!orders.length;
    },
    setSelectedOrder(order, markFunction) {
      this.selectedOrders.push(order);
      setTimeout(() => {
        this.$refs.beautyActions.hide = true;
        this.$refs.beautyActions.markFunction = markFunction;
        this.$refs.beautyActions.markOrder();
      }, 5);
    },
  },
};
</script>

<style lang="scss" scoped>
.tip {
  margin-right: 7px;
}
</style>
